var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        loading: _vm.loading,
        title: _vm.$t("common.detail-text", {
          text: _vm.$t("lbl_amortization")
        })
      }
    },
    [
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_general")) + " ")]),
          _c(
            "a-descriptions",
            { attrs: { column: 5, layout: "vertical" } },
            [
              _c(
                "a-descriptions-item",
                {
                  attrs: {
                    label: _vm.$t("common.number-text", {
                      text: _vm.$t("lbl_amortization")
                    })
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        "data-testid": "amortization-amortization-number"
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.dtDetail.amortizationNumber) + " "
                      )
                    ]
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_document_number") } },
                [
                  _c(
                    "span",
                    {
                      attrs: { "data-testid": "amortization-document-number" }
                    },
                    [_vm._v(" " + _vm._s(_vm.dtDetail.documentNumber) + " ")]
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_transaction_type") } },
                [
                  _c(
                    "span",
                    {
                      attrs: { "data-testid": "amortization-transaction-type" }
                    },
                    [_vm._v(" " + _vm._s(_vm.dtDetail.transactionType) + " ")]
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_branch") } },
                [
                  _c(
                    "span",
                    { attrs: { "data-testid": "amortization-branch" } },
                    [_vm._v(" " + _vm._s(_vm.dtDetail.branchName) + " ")]
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_transaction_date") } },
                [
                  _c(
                    "span",
                    {
                      attrs: { "data-testid": "amortization-transaction-date" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("date")(_vm.dtDetail.transactionDate)) +
                          " "
                      )
                    ]
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_description") } },
                [
                  _c(
                    "span",
                    { attrs: { "data-testid": "amortization-description" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.dtDetail.description || "-") + " "
                      )
                    ]
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_status") } },
                [
                  _c(
                    "span",
                    { attrs: { "data-testid": "amortization-status" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.toTitlecase(_vm.dtDetail.status)) + " "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_currency")) + " ")]),
          _c(
            "a-descriptions",
            { attrs: { column: 5, layout: "vertical" } },
            [
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_currency") } },
                [
                  _c(
                    "span",
                    { attrs: { "data-testid": "amortization-currency" } },
                    [_vm._v(" " + _vm._s(_vm.dtDetail.currencyName) + " ")]
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_currency_rates") } },
                [
                  _c(
                    "span",
                    { attrs: { "data-testid": "amortization-currency-rates" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("currency")(_vm.dtDetail.currencyRate)
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_account")) + " ")]),
          _c(
            "a-descriptions",
            { attrs: { column: 5, layout: "vertical" } },
            [
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_prepaid_account") } },
                [
                  _c(
                    "span",
                    {
                      attrs: { "data-testid": "amortization-prepaid-account" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dtDetail.prepaidAccountCode +
                              " - " +
                              _vm.dtDetail.prepaidAccountDescription
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_expense_account") } },
                [
                  _c(
                    "span",
                    {
                      attrs: { "data-testid": "amortization-expense-account" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dtDetail.expenseAccountCode +
                              " - " +
                              _vm.dtDetail.expenseAccountDescription
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_leasing")) + " ")]),
          _c(
            "a-descriptions",
            { attrs: { column: 5, layout: "vertical" } },
            [
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_lessor_name") } },
                [
                  _c(
                    "span",
                    { attrs: { "data-testid": "amortization-lessor" } },
                    [_vm._v(" " + _vm._s(_vm.dtDetail.lessorName || "-") + " ")]
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_leasing_number") } },
                [
                  _c(
                    "span",
                    { attrs: { "data-testid": "amortization-leasing" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.dtDetail.leasingNumber || "-") + " "
                      )
                    ]
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_leasing_contract_number") } },
                [
                  _c(
                    "span",
                    {
                      attrs: { "data-testid": "amortization-contract-number" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dtDetail.leasingContractNumber || "-") +
                          " "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "fieldset",
        [
          _c("legend", [
            _vm._v(" " + _vm._s(_vm.$t("lbl_voucher_cash_out")) + " ")
          ]),
          _c(
            "a-descriptions",
            { attrs: { column: 5, layout: "vertical" } },
            [
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_use_voucher_cash_out") } },
                [
                  _c("DisplayBoolean", {
                    attrs: { value: _vm.useCashout, mode: "yes" }
                  })
                ],
                1
              ),
              _vm.useCashout
                ? [
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_bank_name") } },
                      [
                        _c(
                          "span",
                          { attrs: { "data-testid": "amortization-bank" } },
                          [_vm._v(" " + _vm._s(_vm.dtDetail.bankName) + " ")]
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      {
                        attrs: {
                          label: _vm.$t("common.number-text", {
                            text: _vm.$t("lbl_cash_out")
                          })
                        }
                      },
                      [
                        _c(
                          "RouterLink",
                          {
                            attrs: {
                              to: {
                                name: "cash-management.cash-in-out.edit",
                                params: {
                                  id: _vm.dtDetail.cashOutSecureId
                                }
                              },
                              target: "_blank",
                              "data-testid": "amortization-cash-out-number"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.dtDetail.cashOutNumber) + " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 14 } }, [
            _c(
              "fieldset",
              [
                _c("legend", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("common.detail-text", {
                          text: _vm.$t("lbl_journal")
                        })
                      ) +
                      " "
                  )
                ]),
                _c("a-table", {
                  attrs: {
                    columns: _vm.journalColumns,
                    "data-source": _vm.dtDetail.journals,
                    "row-key": "journalId",
                    size: "small",
                    "row-class-name": function(_record, index) {
                      return index % 2 ? "bg-white" : "bg-gray-light"
                    },
                    pagination: {
                      showTotal: function(total) {
                        return _vm.$t("lbl_total_items", { total: total })
                      }
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "journal",
                      fn: function(text, record) {
                        return [
                          _c(
                            "RouterLink",
                            {
                              attrs: {
                                to: {
                                  name: "generaljournal.detail",
                                  params: { id: record.journalId }
                                },
                                target: "_blank"
                              }
                            },
                            [_vm._v(" " + _vm._s(text) + " ")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "fieldset",
        { staticClass: "mt-3" },
        [
          _c("legend", [_vm._v(_vm._s(_vm.$t("lbl_details")))]),
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-pagination", {
                    attrs: {
                      size: "small",
                      "default-page-size": 1,
                      showLessItems: "",
                      total: _vm.dtDetail.units.length,
                      "show-total": function(total) {
                        return _vm.$t("common.total-text", {
                          total: total,
                          text: _vm.$t("lbl_unit")
                        })
                      }
                    },
                    model: {
                      value: _vm.currentUnitPage,
                      callback: function($$v) {
                        _vm.currentUnitPage = $$v
                      },
                      expression: "currentUnitPage"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-descriptions",
                    { attrs: { column: 5, layout: "vertical" } },
                    [
                      _vm.dtDetail.units.length !== 0 &&
                      _vm.dtDetail.units[_vm.currentUnitPage - 1]
                        ? [
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: _vm.$t("lbl_unit_code") } },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-testid": "amortization-unit-code"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.dtDetail.units[
                                            _vm.currentUnitPage - 1
                                          ].unitCode || "-"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: _vm.$t("lbl_serial_number") } },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-testid":
                                        "amortization-serial-number"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.dtDetail.units[
                                            _vm.currentUnitPage - 1
                                          ].serialNumber || "-"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "a-descriptions-item",
                              {
                                attrs: { label: _vm.$t("lbl_acquisition_date") }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-testid":
                                        "amortization-acquisition-date"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("date")(
                                            _vm.dtDetail.units[
                                              _vm.currentUnitPage - 1
                                            ].acquisitionDate
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: _vm.$t("lbl_brand") } },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-testid": "amortization-brand"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.dtDetail.units[
                                            _vm.currentUnitPage - 1
                                          ].brand || "-"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: _vm.$t("lbl_type") } },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-testid": "amortization-type"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.dtDetail.units[
                                            _vm.currentUnitPage - 1
                                          ].type || "-"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: _vm.$t("lbl_note") } },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-testid": "amortization-note"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.dtDetail.units[
                                            _vm.currentUnitPage - 1
                                          ].note || "-"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: _vm.$t("lbl_status") } },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-testid": "amortization-status"
                                    }
                                  },
                                  [
                                    _vm.isClosed(
                                      _vm.dtDetail.units[
                                        _vm.currentUnitPage - 1
                                      ].status
                                    )
                                      ? _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name:
                                                  "amortizations.close.detail",
                                                params: {
                                                  id:
                                                    _vm.dtDetail.amortizationId,
                                                  unitId:
                                                    _vm.dtDetail.units[
                                                      _vm.currentUnitPage - 1
                                                    ].unitId
                                                }
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.toTitlecase(
                                                    _vm.dtDetail.units[
                                                      _vm.currentUnitPage - 1
                                                    ].status
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.toTitlecase(
                                                  _vm.dtDetail.units[
                                                    _vm.currentUnitPage - 1
                                                  ].status
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ]
                            )
                          ]
                        : _c(
                            "a-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_unit_code"),
                                "data-testid": "amortization-unit-code"
                              }
                            },
                            [_vm._v(" " + _vm._s("-") + " ")]
                          )
                    ],
                    2
                  )
                ],
                1
              ),
              _vm.dtDetail.units[_vm.currentUnitPage - 1]
                ? _c(
                    "a-col",
                    { attrs: { md: 24, lg: 14 } },
                    [
                      _c("a-table", {
                        attrs: {
                          "row-key": function(r, i) {
                            return i
                          },
                          columns: _vm.unitColumns,
                          "data-source":
                            _vm.dtDetail.units[_vm.currentUnitPage - 1].periods,
                          size: "small",
                          pagination: {
                            showTotal: function(total) {
                              return _vm.$t("lbl_total_items", { total: total })
                            }
                          },
                          "row-class-name": function(_record, index) {
                            return index % 2 ? "bg-white" : "bg-gray-light"
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "footer",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-right",
                                      attrs: {
                                        "data-testid":
                                          "amortization-unit-subtotal"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("lbl_subtotal") + ": "
                                          ) +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.dtDetail.units[
                                                _vm.currentUnitPage - 1
                                              ].unitAmortizationAmount
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1146387857
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16] } },
        [
          _c(
            "a-col",
            [
              _c("a-statistic", {
                attrs: {
                  title: _vm.$t("common.total-text", {
                    text: _vm.$t("lbl_unit_code")
                  }),
                  value: _vm.dtDetail.units.length
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            [
              _c("a-statistic", {
                attrs: {
                  title: _vm.$t("lbl_grand_total"),
                  value: _vm.dtDetail.totalAmortizationAmount
                },
                scopedSlots: _vm._u([
                  {
                    key: "formatter",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c(
                          "span",
                          {
                            attrs: { "data-testid": "amortization-grand-total" }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm._f("currency")(value)) + " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "a-col",
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { "data-testid": "amortization-back" },
                      on: { click: _vm.handleBack }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
                  ),
                  _vm.$can("cancel", "amortization") && _vm.allowCancel
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loadingCancel,
                            "html-type": "button",
                            "data-testid": "amortization-draft",
                            type: "danger"
                          },
                          on: { click: _vm.handleCancel }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("common.cancel-text", {
                                  text: _vm.$t("lbl_amortization")
                                })
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.$can("close", "amortization") && _vm.allowClose
                    ? _c(
                        "RouterLink",
                        {
                          attrs: {
                            to: {
                              name: "amortizations.close",
                              params: { id: _vm.id }
                            },
                            "data-testid": "amortization-close"
                          }
                        },
                        [
                          _c("a-button", { attrs: { type: "danger" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("common.close-text", {
                                    text: _vm.$t("lbl_amortization")
                                  })
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$can("update", "amortization") && _vm.allowEdit
                    ? _c(
                        "RouterLink",
                        {
                          attrs: {
                            to: {
                              name: "amortizations.edit",
                              params: { id: _vm.id }
                            },
                            "data-testid": "amortization-edit"
                          }
                        },
                        [
                          _c("a-button", { attrs: { type: "primary" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("common.edit-text", {
                                    text: _vm.$t("lbl_amortization")
                                  })
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$can("submit", "amortization") && _vm.allowCreate
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loadingCreate
                          },
                          on: { click: _vm.handleCreate }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("common.create-text", {
                                  text: _vm.$t("lbl_amortization")
                                })
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }